import "./Hoe.css";

export default function Hoe() {
  return (
    <div className="feature-area">
      <div className="feature-shape">
        <img src="assets/img/logo.svg" alt="Logo" width="240" />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
            <div className="feature-img"></div>
          </div>
          <div className="col-lg-6 p-0">
            <div className="feature-content">
              <ul>
                <li>
                  <i>1.</i>
                  <p>
                    Loopt het ongeval via verzekering? Licht eerst uw makelaar
                    in en geef onze carrosserie door als “hersteller”.
                  </p>
                </li>
                <li>
                  <i>2.</i>
                  <p>
                    Contacteer ons via telefoon, messenger, whatsapp, bericht of
                    mail.
                  </p>
                </li>
                <li>
                  <i>3.</i>
                  <p>
                    Wij boeken een afspraak in voor een prijsofferte. Via
                    verzekering? De verzekering maakt een afspraak wanneer een
                    expert kan langskomen in onze carrosserie.
                  </p>
                </li>
                <li>
                  <i>4.</i>
                  <p>
                    Ofwel blijft de wagen ineens bij ons om te herstellen ofwel
                    wordt er een afspraak gemaakt. Gedurende de volledige
                    herstelling kan u gebruikmaken van een gratis vervangwagen.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
