import React, { useState, useEffect } from "react";
import About from "./components/about/About";
import Address from "./components/address/Address";
import Certificaten from "./components/certificaten/Certificaten";
import Contact from "./components/contact/Contact";
import Erkenning from "./components/erkenning/Erkenning";
import Eurogarant from "./components/eurogarant/Eurogarant";
import Footer from "./components/footer/Footer";
import Hero from "./components/hero/Hero";
import Hoe from "./components/hoe/Hoe";
import Navbar from "./components/navbar/Navbar";
import Preloader from "./components/preloader/Preloader";
import Services from "./components/services/Services";
import Vervangwagen from "./components/vervangwagen/Vervangwagen";
import Welcome from "./components/welcome/Welcome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function App() {
  const [showPreloader, setShowPreloader] = useState(true);
  const isPopup = true;

  const showSwal = React.useCallback(() => {
    if (isPopup) {
      withReactContent(Swal).fire({
        title: "Gesloten",
        color: "#212121",
        text: "Wij zijn gesloten van 15 Augustus 2024 tot en met 16 Augustus 2024.",
        confirmButtonColor: "#f22a2a",
      });
    } else {
      return 0;
    }
  }, [isPopup]);

  useEffect(() => {
    showSwal();
    const handleLoad = () => {
      setShowPreloader(false);
    };

    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setShowPreloader(false);
      } else {
        setShowPreloader(true);
      }
    };

    handleResize();

    window.addEventListener("load", handleLoad);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("load", handleLoad);
      window.removeEventListener("resize", handleResize);
    };
  }, [showSwal]);

  return (
    <div className="App">
      {showPreloader && <Preloader className="absolute" />}
      <Navbar />
      <Hero />
      <Address />
      <Welcome />
      <Erkenning />
      <Certificaten />
      <About />
      <Services />
      {/* <Vacatures /> */}
      <Vervangwagen />
      <Hoe />
      <Eurogarant />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
