import "./Certificaten.css";
import Axalta from "../../assets/img/partners/Axalta.jpg";
import ARN from "../../assets/img/partners/ARN.png";
import GOSMART from "../../assets/img/partners/GoSmart.22d9cb65f6eac8674677-removebg-preview.png";

export default function Certificaten() {
  return (
    <section id="erkenning" className="process-area pt-100 pb-70">
      <div className="section-title">
        <h2>Onze partners:</h2>
      </div>
      <div className="container flex w-100 m-auto">
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={Axalta} style={{ width: "280px" }} alt="" />
        </div>
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={ARN} style={{ width: "280px" }} alt="" />
        </div>
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={GOSMART} style={{ width: "280px" }} alt="" />
        </div>
      </div>
    </section>
  );
}
